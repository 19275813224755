body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
p,
span {
  white-space: pre-wrap;
}
h1,
h2 {
  font-weight: 500;
  text-align: center;
}
h1 {
  font-size: 32px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-weight: 400;
  text-align: center;
  font-size: 16px;
}

ul, ol {
  padding: 2px 28px
}

li {
  padding: 2px 0;
}

/* Estilos específicos para anular los estilos globales dentro de .quill */
.quill h1,
.quill h2,
.quill h3,
.video-description h1,
.video-description h2,
.video-description h3 {
  all: unset;
  text-align: left;
  white-space: normal;
  font-weight: normal;
  font-size: inherit;
}

/* Vuelve a aplicar estilos básicos si es necesario */
.quill h1 {
  font-size: 32px;
  font-weight: bold;
}

.video-description h1, .video-description h2, .video-description h3 {
  font-weight: bold;
}

.video-details.other h1, h2, h3 {
  font-weight: bold;
  font-size: 14px;
}

.video-description h1 {
  font-size: 22px;
}

.video-description h2 {
  font-size: 18px;
}

.video-description h3 {
  font-size: 16px;
}

.quill h2 {
  font-size: 24px;
  font-weight: bold;
}

.quill h3 {
  font-size: 18px;
  font-weight: normal;
}

.pageHeader {
  margin: auto;
  text-align: center;
}


